<template>
  <v-container v-if="environment" class="pa-0">
    <game-info
      v-if="provider"
      :game="game"
      :loading="firstLoad"
      :provider="provider"
      :game-instances="gameInstances"
    />

    <v-alert
      v-if="environment.force_certified"
      dense text outlined
      dismissible
      type="warning"
    >
      {{ labels.onlyCertified }}
    </v-alert>

    <v-row no-gutters class="pa-0">
      <v-tabs right v-model="tab">
        <v-tabs-slider color="primary" />
        <v-tab
          v-for="section in sections"
          :disabled="loading"
          :key="section"
        >
          {{ section }}
        </v-tab>
      </v-tabs>

      <!-- TODO: use <keep-alive>? -->
      <v-skeleton-loader
        v-if="firstLoad"
        class="flex"
        loading
        type="table"
      />
      <version-table
        v-else-if="tab === 0"
        backend
        :actions="actions['backend']"
        :headers="headers['backend']"
        :hidden="hiddenBackendVersions"
        :game-instances="gameInstances"
        :items="filteredBackendVersions"
        @scale="scale"
        :scale="showScaleSlider"
        @toggle-incompatible="toggleIncompatible"
        :key="`backend-${tableKey}`"
      />

      <version-table
        v-else
        :actions="actions['frontend']"
        :game-instances="gameInstances"
        :headers="headers['frontend']"
        :items="frontendVersions"
        :key="`frontend-${tableKey}`"
      />
    </v-row>

    <confirmation-dialog
      v-if="showConfirmation"
      :callbackMethod="callbackOnceConfirmed"
      :confirmationMsg="confirmationMsg"
      @close="showConfirmation = false"
      :show="showConfirmation"
      class="dialog"
    />

    <deploy-dialog
      v-if="deployDialog"
      @close="closeDeployDialog"
      @confirm="callbackOnceConfirmed"
      :kind="deployKind"
      :show="deployDialog"
      class="dialog"
    />

  </v-container>
</template>

<script>
/* eslint-disable no-param-reassign,no-case-declarations,consistent-return,no-mixed-operators */
/* eslint no-unused-vars: ["error", { "argsIgnorePattern": "^_" }] */
import { mapActions, mapState } from 'vuex';
import {
  every,
  find,
  get,
  head,
  isEmpty,
  isEqual,
  some,
  sortBy,
} from 'lodash';
import { labels } from '@/assets/texts.json';
import { formUpload, sendData } from '@/helpers';

export default {
  name: 'gameEdit',
  props: {
    envName: {
      type: String,
      default: '',
      required: false,
    },
    id: {
      type: Number,
      required: false,
    },
    image: {
      type: String,
      default: '',
      required: true,
    },
  },
  components: {
    confirmationDialog: () => import('@/components/confirmationDialog.vue'),
    deployDialog: () => import('@/components/deployDialog.vue'),
    gameInfo: () => import('@/components/gameInfo.vue'),
    versionTable: () => import('@/components/versionTable.vue'),
  },
  beforeDestroy() {
    this.clearInterval();
  },
  created() {
    this.fetchProviders().then(() => {
      // Get first the environment permissions, then the provider permissions
      // and merge both results
      this.provider = find(this.providers, { code: this.providerCode });
      this.fetchProviderPermissions(this.providerCode).then((permissions) => {
        this.allPermissions = { ...permissions, ...this.permissions };
      });
    });
    this.fetchAll();
    this.setTimerMethod(this.getGameInstance);
    this.setInterval();
    // Fetch environment if not already there; this happens when you reload (F5)
    // while in this view, since the environment is usually loaded from vuex
    // as it was already fetched by the environment view
    if (isEmpty(this.environment)) {
      this.fetchEnvironment(this.environmentID);
    }
  },
  data: () => ({
    allPermissions: {},
    callbackOnceConfirmed: () => {},
    certifiedVersions: [],
    confirmationMsg: null,
    deployDialog: false,
    deployKind: null,
    firstLoad: true,
    headers: {
      backend: [
        {
          text: 'Version',
          value: 'version',
          sortable: true,
        },
        {
          text: 'Frontend',
          value: 'frontend',
          sortable: true,
        },
        {
          text: 'Replicas',
          value: 'replicas',
          sortable: false,
        },
        {
          text: 'CSHA',
          value: 'csha',
          sortable: true,
        },
        {
          text: 'Logiclib',
          value: 'lglib-version',
          sortable: true,
        },
        {
          text: 'Comments',
          value: 'last-comment',
          sortable: false,
        },
        {
          text: 'Certifications',
          value: 'certifications',
          sortable: false,
        },
      ],
      frontend: [
        {
          text: 'Version',
          value: 'version',
          sortable: true,
        },
        {
          text: 'Date',
          value: 'created',
          sortable: true,
        },
        {
          text: 'Comments',
          value: 'last-comment',
          sortable: false,
        },
      ],
    },
    labels,
    provider: null,
    sections: ['backend', 'frontend'],
    selectedItem: [],
    showConfirmation: false,
    showIncompatible: true,
    showScaleSlider: false,
    tab: 0,
    tableKey: 0, // used to force re-rendering of tables
  }),
  computed: {
    ...mapState('fetch', [
      'backendVersions',
      'certificationAuthorities',
      'environment',
      'environments',
      'frontendVersions',
      'gameInstances',
      'permissions',
      'providers',
    ]),
    ...mapState('shared', [
      'gameInstance',
      'loading',
      'refresh',
      'selectedBackend',
      'selectedFrontend',
    ]),
    actions() {
      return {
        backend: [
          {
            action: this.deployOrUpdate,
            allowed: true,
            icon: '$deploy',
            name: this.labels.deploy,
            needsConsolidation: false,
            needsSetup: false,
            param: this.labels.install,
          },
          {
            action: this.removeGame,
            allowed: this.canRemoveGame,
            color: 'error',
            icon: '$remove',
            name: this.labels.remove,
            needsConsolidation: false,
            needsSetup: true,
          },
          {
            action: this.deployOrUpdate,
            allowed: true,
            icon: '$update',
            name: this.labels.update,
            needsConsolidation: true,
            needsSetup: true,
            param: this.labels.update,
          },
          {
            action: this.to,
            allowed: true,
            icon: '$logs',
            name: this.labels.logs,
            needsConsolidation: true,
            needsSetup: true,
            param: this.getLogsRoute,
          },
          {
            action: this.certifyVersion,
            allowed: !isEmpty(this.certificationAuthorities),
            icon: '$certify',
            modal: true,
            name: this.labels.certify,
            needsConsolidation: true,
            needsSetup: true,
            param: `/certifications/${this.image}`,
          },
          {
            action: this.sendComment,
            allowed: this.canAddBackComment,
            icon: '$commentSend',
            modal: true,
            name: this.labels.addComment,
            needsConsolidation: false,
            needsSetup: false,
            param: `/environment/${this.environment.name}/game/${this.image}/comment`,
          },
          {
            action: this.restart,
            allowed: this.canRestartGame,
            icon: '$restart',
            name: this.labels.restart,
            needsConsolidation: false,
            needsSetup: true,
          },
          {
            action: () => { this.showScaleSlider = !this.showScaleSlider; },
            allowed: this.canScaleGame,
            icon: '$scale',
            name: this.labels.scale,
            needsConsolidation: true,
            needsSetup: true,
          },
        ],
        frontend: [
          {
            action: this.showBackends,
            allowed: true,
            icon: '$info',
            modal: true,
            name: this.labels.bev,
            needsConsolidation: false,
            needsSetup: true,
          },
          {
            action: this.sendComment,
            allowed: this.canAddFrontComment,
            icon: '$commentSend',
            name: this.labels.addComment,
            modal: true,
            needsConsolidation: false,
            needsSetup: false,
            param: `/environment/${this.environment.name}/game/${this.image}/frontcomment`,
          },
        ],
      };
    },
    canAddBackComment() {
      return get(this.allPermissions, 'provider_add_comment');
    },
    canAddFrontComment() {
      return get(this.allPermissions, 'provider_add_front_comment');
    },
    canDeployGame() {
      return (
        get(this.allPermissions, 'update_version')
        && get(this.allPermissions, 'provider_deploy')
      );
    },
    canRemoveGame() {
      return (
        get(this.allPermissions, 'remove_game')
        && get(this.allPermissions, 'provider_undeploy')
      );
    },
    canRestartGame() {
      return get(this.allPermissions, 'k8s_restart_game');
    },
    canScaleGame() {
      return get(this.allPermissions, 'k8s_scale_game');
    },
    environmentID() {
      // Check if we got the environment ID from the route params,
      // otherwise get it by name from `environments`
      if (!isEmpty(this.id)) return this.id;
      const id = get(
        this.environments.filter((e) => e.name === this.envName)[0],
        'id',
      );
      return id;
    },
    game() {
      return this.image.split('_')[2];
    },
    filteredBackendVersions() {
      return this.backendVersions.filter(
        (v) => this.isCompatible(v) || this.showIncompatible,
      );
    },
    hiddenBackendVersions() {
      return this.backendVersions.length - this.filteredBackendVersions.length;
    },
    isItemSelected() {
      return !isEmpty(this.selectedItem);
    },
    versionItem() {
      return this.isItemSelected ? this.selectedItem[0] : {};
    },
    providerCode() {
      return this.image.split('_')[1];
    },
    refreshInterval: {
      // Trigger a faster fetch frequency when there's a pending operation
      // We should be using websockets instead ;(
      get() {
        return (
          every(this.gameInstances, { transition: 'OK', consolidated: true })
          || isEmpty(this.gameInstances)
          // Don't fetch too much in case of failure
          || some(this.gameInstances, { transition: 'KO' })
        ) ? 30000 : 5000;
      },
      set() { // TODO: check what to do when scale/restart/deploy gets a 500
        this.clearInterval();
      },
    },
    scaleWarning() {
      return (
        `You are scaling this game instance to ${this.replicas} replicas`
      );
    },
  },
  methods: {
    ...mapActions({
      clearInterval: 'fetch/clearInterval',
      errorMsg: 'shared/errorMsg',
      fetchCertificationAuthorities: 'fetch/fetchCertificationAuthorities',
      fetchEnvironment: 'fetch/fetchEnvironment',
      fetchGameInstances: 'fetch/fetchGameInstances',
      fetchProviders: 'fetch/fetchProviders',
      fetchProviderPermissions: 'fetch/fetchProviderPermissions',
      fetchVersions: 'fetch/fetchVersions',
      infoMsg: 'shared/infoMsg',
      manageGame: 'fetch/manageGame',
      manageWorkload: 'fetch/manageWorkload',
      selectBackend: 'shared/selectBackend',
      selectFrontend: 'shared/selectFrontend',
      setInterval: 'fetch/setInterval',
      setTimerMethod: 'fetch/setTimerMethod',
    }),
    addOrUpdate(array, item) {
      const i = array.findIndex((arrItem) => arrItem.id === item.id);
      if (i > -1) array[i] = item;
      else array.push(item);
    },
    canBeDeployed(version) {
      return (
        this.canDeployGame && (
          !this.environment.force_certified
          || this.certifiedVersions.includes(version)
        )
      );
    },
    certifyVersion(item, url, certificationAuthority, backend) {
      if (item.version && item.csha && certificationAuthority) {
        sendData({
          url,
          body: {
            currentVersion: backend ? item.tag : item.version,
            environment: item.environment,
            versionToCertify: item.tag,
            cshaToCertify: item.csha,
            certificationAuthority,
          },
        }).then((response) => {
          this.infoMsg(response.data.message);
        }).catch((error) => {
          this.errorMsg(error.data.message);
        });
      }
    },
    closeDeployDialog() {
      this.deployDialog = false;
    },
    deployOrUpdate(item, kind) {
      /*
       * Shows a dialog with Backend<>Frontend version selectors and
       * calls deployVersion
      */
      this.selectBackend(item.tag);
      this.selectFrontend(
        get(this.gameInstance, 'game_frontend_version'),
      );
      this.callbackOnceConfirmed = () => {
        this.deployDialog = false;
        this.deployVersion(item);
      };
      this.deployKind = kind;
      this.deployDialog = true;
    },
    deployVersion(item) {
      let method = 'POST';
      if (!isEmpty(this.gameInstance)) { // this is an update
        method = 'PUT';
        if (this.selectedFrontend === get(
          this.gameInstance,
          'game_frontend_version',
        ) && this.selectedBackend === get(
          this.gameInstance,
          'game_backend_full_version',
        )) {
          this.infoMsg(this.labels.sameVersion);
          return;
        }
      }
      if (!this.canBeDeployed(item.version)) {
        this.errorMsg(this.labels.cannotDeploy);
      } else if (item.isBackend && !item.csha) {
        this.errorMsg(this.labels.emptyCSHA);
      } else if (!this.isCompatible(item.version)) {
        this.showConfirmation = true;
        this.confirmationMsg = `Attention. ${this.labels.incompatible}`;
        this.callbackOnceConfirmed = () => {
          this.showConfirmation = false;
          this.gameManagement(item, method);
        };
      } else {
        this.gameManagement(item, method);
      }
    },
    fetchAll() {
      const promises = [
        this.fetchCertificationAuthorities(),
        this.getBackendVersions(),
        this.getFrontendVersions(),
      ];
      // Wait for all promises to end, then get gameInstances
      Promise.all(promises).then(() => {
        this.getGameInstance().then(() => { this.firstLoad = false; });
      });
    },
    gameManagement(item, method) {
      const data = {
        image: this.image,
        backend_version_from: get(
          this.gameInstance,
          'game_backend_full_version',
        ),
        frontend_version_to: this.selectedFrontend,
        frontend_version_from: get(
          this.gameInstance,
          'game_frontend_version',
        ),
        backend_version_to: this.selectedBackend,
      };
      this.manageGame({
        environmentID: this.environment.id,
        method,
        data,
      }).then((response) => {
        this.updateGameInstances(get(response, 'gameInstance')); // TODO: Review
      });
    },
    getAssociatedItem(gi) { // TODO: we only use this for BE, remove the FE part
      // This is like getCurrentGameInstance but the other way around
      if (isEmpty(gi)) return;
      const obj = this.tab ? this.frontendVersions : this.backendVersions;
      const field = this.tab ? 'version' : 'tag';
      const param = (
        `${this.tab ? 'frontend_version' : 'backend_version'}.${field}`
      );
      return obj.find((o) => o[field] === get(gi, param));
    },
    getBackendVersions() {
      return this.fetchVersions({
        backend: true,
        full: true, // get full object instead of simplified list
        params: { image: this.image },
      }).then(() => {
        this.backendVersions.forEach((game) => {
          game.numComments = game.comments.length;
          game.installed = false;
          game.isCompatible = this.isCompatible(game);
          game.lastComment = (
            game.numComments
              ? game.comments[0].text
              : null
          );
        });
      });
    },
    getCurrentVersion(item) {
      const param = (
        item.isBackend ? 'game_backend_full_version' : 'game_frontend_version'
      );
      return get(this.gameInstance, param);
    },
    getGameInstance() {
      return this.fetchGameInstances({
        params: {
          environment_id: this.environment.id,
          image: this.image,
        },
      }).then(() => {
        this.gameInstances.forEach((gi) => {
          const game = this.getAssociatedItem(gi);
          game.installed = true;
          game.replicas = gi.replicas;
        });
      });
    },
    getFrontendVersions() {
      return this.fetchVersions({
        backend: false,
        params: { image: this.image },
      }).then(() => {
        this.frontendVersions.forEach((frontend) => {
          frontend.numComments = frontend.comments.length;
          frontend.lastComment = (
            frontend.numComments
              ? frontend.comments[0].text
              : null
          );
        });
      });
    },
    getLatestGameInstance() {
      return head(
        sortBy(
          this.gameInstances.filter((gi) => gi.consolidated),
          'game_backend_full_version',
        ),
      );
    },
    getLogsRoute(game) {
      return {
        name: 'game-logs',
        params: {
          envName: this.environment.name,
          image: game.image,
          version: game.version,
        },
      };
    },
    isCompatible(version) {
      // If a version matches environment's core version, it's compatible
      if (isEmpty(this.environment.core_version)) return true;
      const v = version.gs_logiclib_version;
      if (v) {
        return isEqual(
          v.split('.').slice(0, 2),
          this.environment.core_version.split('.').slice(0, 2),
        );
      }
      return false;
    },
    removeGame(item) {
      this.confirmationMsg = `Attention. ${this.labels.removeWarning}`;
      this.callbackOnceConfirmed = () => {
        this.showConfirmation = false;
        this.gameInstance.transition = 'RM';
        this.gameManagement(item, 'DELETE');
      };
      this.showConfirmation = true;
    },
    restart() {
      this.confirmationMsg = `Attention. ${this.labels.restartWarning}`;
      this.callbackOnceConfirmed = () => {
        this.showConfirmation = false;
        const body = {
          instance_id: get(this.gameInstance, 'id'),
          provider: this.providerCode,
          role: 'game',
        };
        this.gameInstance.transition = 'RE';
        this.manageWorkload({
          environmentID: this.environment.id,
          body,
          method: 'PUT',
        });
      };
      this.showConfirmation = true;
    },
    scale(replicas) {
      this.confirmationMsg = `Attention. ${this.scaleWarning}`;
      this.callbackOnceConfirmed = () => {
        this.showConfirmation = false;
        const body = {
          instance_id: get(this.gameInstance, 'id'),
          provider: this.providerCode,
          replicas,
          role: 'game',
        };
        this.gameInstance.transition = 'UP';
        this.manageWorkload({
          environmentID: this.environment.id,
          body,
          method: 'PATCH',
        });
        this.showScaleSlider = false;
      };
      this.showConfirmation = true;
    },
    sendComment(
      item,
      url,
      comment,
      backend,
    ) {
      if (!comment) return;
      const form = new FormData();
      form.append('comment', comment);
      form.append('version', backend ? item.tag : item.version);
      form.append('currentVersion', backend ? item.tag : item.version);
      if (get(item, 'image')) form.append('frontImage', item.image);
      formUpload({
        url,
        config: {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
        form,
      }).then((response) => {
        this.infoMsg(response.message);
        const now = new Date();
        // Fake local data with new comment
        const dtOptions = {
          day: 'numeric',
          hour: 'numeric',
          hour12: true,
          minute: 'numeric',
          month: 'short',
          timeZone: 'CET',
          year: 'numeric',
        };
        item.lastComment = comment;
        item.numComments += 1;
        item.comments.unshift({
          text: comment,
          modified: new Intl.DateTimeFormat('default', dtOptions).format(now),
          user: { username: this.$keycloak.userName },
        });
        this.tableKey += 1; // re-render table
      });
    },
    to(game, routeMethod) {
      const route = routeMethod(game);
      this.$router.push(route);
    },
    toggleIncompatible() {
      this.showIncompatible = !this.showIncompatible;
    },
    updateGameInstances(gameInstance) {
      // The idea here is to construct the local GI object from the JSON
      // response without waiting for another fetchAll API call
      this.addOrUpdate(this.gameInstances, gameInstance);
    },
  },
  watch: {
    refreshInterval: function refreshInterval(val) {
      this.clearInterval();
      this.setInterval(val);
    },
  },
};
</script>

<style>
  .version--highlight {
    background-color: var(--v-warning-base) !important;
  }
  .version--consolidated {
    background-color: var(--v-primary-base) !important;
  }
  .version--failed {
    background-color: var(--v-error-base) !important;
  }
  .version--removing {
    animation: failedcolorchange 1s linear infinite alternate;
  }
  .version--updating {
    animation: updatingcolorchange 1s linear infinite alternate;
  }
  .version--restarting {
    animation: restartcolorchange 1s linear infinite alternate;
  }
  @keyframes restartcolorchange {
    from {background-color: var(--v-warning-lighten1); }
    to {background-color: transparent;}
  }
  @keyframes failedcolorchange {
    from {background-color: var(--v-error-lighten1);}
    to {background-color: transparent;}
  }
  @keyframes updatingcolorchange {
    from {background-color: var(--v-accent-base);}
    to {background-color: transparent;}
  }
  .text-truncate {
    max-width: 5vw;
  }
  .v-data-table {
    width: 100%;
  }
  .certification-chip {
    margin: 0 1px;
    border-color: black !important;
    border-width: 1px;
    border-style: outset;
  }
  .action-btn {
    margin: 0 1px;
    padding: 0 .3vw;
  }
  .v-data-footer__select {
    display: ruby;
  }
  .dialog {
    z-index: 9999;
  }
  .v-card__title {
    word-break: break-word;
  }
</style>
